import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductosService {
  url: string;
  filtroIdMarca = new BehaviorSubject(null);
  $filtroIdMarca = this.filtroIdMarca.asObservable();

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlProductos + "ecommerce";
  }

  get(queryParams): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(queryParams),
    };
    return this.http.get(this.url, requestOptions);
  }

  getByDescripcion(descripcion: string): Observable<any> {
    return this.http.get(this.url + `/${descripcion}`);
  }

  getTallas(idEmpresaProducto: string): Observable<any> {
    return this.http.get(`${this.url}/talla-producto/${idEmpresaProducto}`);
  }

  getMasNuevos() {
    return this.http.get(`${this.url}/mas-nuevos`);
  }

  getMasVendidos() {
    return this.http.get(`${this.url}/mas-vendidos`);
  }
}
