import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {RegistrateCorreoComponent} from './modals/registrate-correo/registrate-correo.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(public dialog: MatDialog) {}

  modalRegistro() {
    this.dialog.open(RegistrateCorreoComponent, {
      width: '400px',
      height: '500px'
    });
  }
}