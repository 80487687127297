import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {CotizaFormComponent} from './shared/generales/cotiza-form/cotiza-form.component';
import {ContactFormComponent} from './shared/generales/contact-form/contact-form.component';
import {LlamaFormComponent} from './shared/generales/llama-form/llama-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public dialog: MatDialog) {

  }

  llamaForm(): void {
    this.dialog.open(LlamaFormComponent, {
      width: '350px',
    });
  }

  cotizaForm(): void {
    this.dialog.open(CotizaFormComponent, {
      width: '350px',
    });
  }

  contactForm(): void {
    this.dialog.open(ContactFormComponent, {
      width: '350px',
    });
  }

}
