import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarjetaProductosComponent } from './tarjeta-productos/tarjeta-productos.component';
import {RouterModule} from '@angular/router';
import {ParsearImagenPipe} from './pipes/parsear-imagen.pipe';
import {LlamaFormComponent} from './llama-form/llama-form.component';
import {
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatCardModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatInputModule,
  MatTooltipModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {CotizaFormComponent} from './cotiza-form/cotiza-form.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {ProductSliderComponent} from './product-slider/product-slider.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import { BtnCuponComponent } from './btn-cupon/btn-cupon.component';

@NgModule({
  declarations: [
    TarjetaProductosComponent,
    ParsearImagenPipe,
    LlamaFormComponent,
    CotizaFormComponent,
    ContactFormComponent,
    ProductSliderComponent,
    BtnCuponComponent
  ],
  exports: [
    TarjetaProductosComponent,
    ParsearImagenPipe,
    ProductSliderComponent,
    BtnCuponComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    SlickCarouselModule,
    MatCardModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatTooltipModule
  ],
  entryComponents: [
    LlamaFormComponent,
    CotizaFormComponent,
    ContactFormComponent
  ]
})
export class GeneralesModule { }
