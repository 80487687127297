import {Injectable} from '@angular/core';
import {CanActivate, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserstoreService} from '../services/nuevaLogica/clientes/userstore.service';

@Injectable({
  providedIn: 'root'
})

export class UserGuard implements CanActivate {
  constructor(
    private loginService: UserstoreService,
    private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginService.validToken()) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }

}
