import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShopRoutingModule} from './shop-routing.module';
import {ShopComponent} from './shop.component';
import {LayoutModule} from '../layout/layout.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LandingFixService} from '../core/services/landing-fix.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {UserGuard} from '../core/guards/user.guard';
import {MatNativeDateModule} from '@angular/material/core';
import {NgbModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {LanzamientosComponent} from './lanzamientos/lanzamientos.component';
import {DetallesProductoComponent} from './lanzamientos/modals/detalles-producto/detalles-producto.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {EmpresaInterceptorService} from '../core/interceptors/empresa-interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

@NgModule({
  declarations: [
    ShopComponent,
    LanzamientosComponent,
    DetallesProductoComponent,
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    LayoutModule,
    SlickCarouselModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    LazyLoadImageModule,
    NgbModule,
    NgbAlertModule,
    MatNativeDateModule,
    // NgAisModule.forRoot()
  ],
  providers: [
    LandingFixService,
    UserGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmpresaInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    DetallesProductoComponent,
  ]
})
export class ShopModule {
}
