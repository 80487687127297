import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CitasService} from '../../core/services/citas.service';
import {MarcasService} from '../../core/services/marcas.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {animate, style, transition, trigger} from '@angular/animations';
import {BenefictsService} from '../../core/services/beneficts.service';
import {UserStoreService} from '../../core/services/user-store.service';
import {MatDialog} from '@angular/material';
import {DetallesProductoComponent} from './modals/detalles-producto/detalles-producto.component';

@Component({
  selector: 'app-lanzamientos',
  templateUrl: './lanzamientos.component.html',
  styleUrls: ['./lanzamientos.component.scss'],
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({opacity: 0.1}),
        animate(1000, style({opacity: 0.1}))
      ]),
      transition('* => fadeIn', [
        style({opacity: 0.1}),
        animate(1000, style({opacity: 0.1}))
      ])
    ])
  ]
})
export class LanzamientosComponent implements OnInit {
  title: string;
  public animation: any;
  productosSeleccionados = [];
  descuento: any = false;
  productosLanzamiento: any;
  formulario = new FormGroup({
    nombre: new FormControl('', Validators.required),
    correo: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    telefono: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]{10}')])),
    cp: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]{4,5}')])),
    comentarios: new FormControl('', Validators.maxLength(255)),
  });
  public sliderConfig: any = {
    autoplay: true,
    autoplaySpeed: 300000
  };

  constructor(private citasService: CitasService,
              private marcasService: MarcasService,
              private router: Router,
              private route: ActivatedRoute,
              private benefitSevice: BenefictsService,
              public userStore: UserStoreService,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.title = 'LANZAMIENTOS';
    this.getProductos();
    if (this.userStore.validToken()) {
      this.getUsuarioDescuento();
    }
  }

  getProductos() {
   /* this.productosService.productosLanzamiento().subscribe({
      next: data => {
        for (let i = 0; i < data.length; i++) {
          data[i].imagenes = JSON.parse(data[i].imagenes);
        }
        this.productosLanzamiento = data;
      }
    });*/
  }

  getUsuarioDescuento() {
    this.benefitSevice.getBeneficiarioByIdUsuario().subscribe(data => {
      this.descuento = data[0].descripcion;
    });
  }

  getImg(imagen) {
    if (imagen) {
      return imagen.replace(/[\\"\[\]]/g, '');
    }
  }

  agregarProducto(producto: any) {
    const productoFiltrado = this.productosSeleccionados.filter(product => product.id === producto.id);
    if (productoFiltrado.length) {
      this.productosSeleccionados.splice(this.productosSeleccionados.indexOf(producto), 1);
    } else {
      this.dialog.open(DetallesProductoComponent, {
        data: producto,
        width: '400px',
      }).afterClosed().subscribe(data => {
        if (data) {
          this.productosSeleccionados.push(data);
          Swal.fire({
            icon: 'question',
            title: '¿Deseas pagar ahora?',
            showConfirmButton: true,
            confirmButtonText: 'Pagar ahora',
            showCancelButton: true,
            cancelButtonText: 'Pagar despúes',
          }).then( val => {
            if (val.value) {
              this.router.navigate(['/checkout/pago']);
            }
          });
        }
      });
    }
  }

  productoExistente(producto) {
    return this.productosSeleccionados.filter(product => product.id === producto.id).length;
  }

  guardarCita() {
    const idProductos = [];

    this.productosSeleccionados.forEach(producto => idProductos.push(producto.id));
    const cita = {
      idEmpresa: 1,
      nombre: this.formulario.controls.nombre.value,
      correo: this.formulario.controls.correo.value,
      comentarios: this.formulario.controls.comentarios.value,
      telefono: this.formulario.controls.telefono.value,
      cp: this.formulario.controls.cp.value,
      productos: idProductos,
    };

    // Swal.fire({
    //   // title: 'Agendando cita, por favor espere',
    //   title: 'Procesando..',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading();
    //   }
    // });

    this.citasService.postLanzamiento(cita).subscribe({
      complete: () => {
        /*Swal.fire({
          icon: 'success',
          title: 'Tu cita ha sido agendada con éxito'
        }).then(() => {
          this.router.navigate(['/']);
        });*/
        localStorage.setItem('datosCliente', JSON.stringify(this.formulario.value));
        // this.productosSeleccionados.forEach(producto => this.buyNow(producto, producto.cantidad, producto.talla));
        this.router.navigate(['/checkout/pago']);
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Estás listo para continuar con el pago'
        // }).then(result => {
        //   // if (result) {
        //     this.router.navigate(['/checkout']);
        //   // }
        // });
      }
    });
  }

  public buyNow( quantity, size) {
    /*if (product.idDescuentoCatalogo != null && product.idTipoDescuento == 1) {
      product.precioVenta = (product.precioVenta - (product.precioVenta * product.cantidadDescuento / 100));
    }

    if (product.idDescuentoCatalogo != null && product.idTipoDescuento == 2) {
      product.precioVenta = product.precioVenta - product.cantidadDescuento;
    }*/

    // this.cartService.addToCart(product, +quantity, size);
  }

}
