import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {enviroment} from '../variables';

@Injectable({
  providedIn: 'root'
})
export class CitasService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = enviroment.apiUrl + 'citas';
  }

  post(cita) {
    return this.http.post(this.url, cita);
  }

  postCitaV2(cita) {
    return this.http.post(this.url + '-v2', cita);
  }

  postLanzamiento(cita) {
    return this.http.post(this.url + '-lanzamiento', cita);
  }
}
