import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrlClientes}newsletter/ecommerce`;
  }

  saveRegistro(registro): Observable<any> {
    return this.http.post<any>(this.url, registro);
  }
}