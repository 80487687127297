// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.sneakerstore.com.mx/api/',
  apiUrlProductos: 'https://backend.desarrollatech.com/nueva-base/productos/',
  apiUrlClientes: 'https://backend.desarrollatech.com/nueva-base/clientes/',
  apiUrlOrdenes: 'https://backend.desarrollatech.com/nueva-base/ordenes/',
  apiUrlEcommerce: 'https://backend.desarrollatech.com/nueva-base/ecommerce/',
  apiUrlAtencionClientes: 'https://backend.desarrollatech.com/nueva-base/atencion-clientes/',
  apiUrlDistribuidores: 'https://backend.desarrollatech.com/nueva-base/distribuidores/',
  apiUrlCitas: 'https://backend.desarrollatech.com/nueva-base/citas/',
  apiUrlFinanzas: 'https://backend.desarrollatech.com/nueva-base/finanzas/',
  apiUrlCupones: 'https://backend.desarrollatech.com/nueva-base/cupones/',
  paymentUrl: 'https://payment.sneakerstore.com.mx',
  paymentMP: 'https://api.mercadolibre.com/checkout/preferences?access_token=APP_USR-4377320517592414-111805-48ec209cee172ce655580a1e24ec71a4-122188646',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
