import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { LandingFixService } from "../../core/services/landing-fix.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { RegisterUserComponent } from "./widgets/register-user/register-user.component";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "./widgets/login/login.component";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { CarritoService } from "../../core/services/nuevaLogica/checkout/carrito.service";
import { UserstoreService } from "../../core/services/nuevaLogica/clientes/userstore.service";
import { ProductosService } from "src/app/core/services/nuevaLogica/productos/productos.service";
import { Router } from "@angular/router";
import { Carrito } from "src/app/core/interfaces/checkout/carrito";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public shoppingCartItems: Carrito[] = [];

  elementosCarrito: number;
  public show = false;
  tokenIsValid: boolean;
  user: any;
  subCategorias: any;
  public showMenu = false;
  showLogin = false;
  idMarcaUrl: any;
  menus: any = [
    {
      name: "Sneakers",
      image: "assets/images/jor.png",
      url: {
        producto: "sneakers",
      },
      subcategorias: [
        {
          sub: "Hombre",
          url: {
            producto: "sneakers",
            categoria: "hombre",
          },
          img: "assets/images/jordan5.jpg",
        },
        {
          sub: "Mujer",
          url: {
            producto: "sneakers",
            categoria: "mujer",
          },
          img: "assets/images/dior.jpg",
        },
        {
          sub: "Niños",
          url: {
            producto: "sneakers",
            categoria: "nino",
          },
          img: "assets/images/nino.jpg",
        },
        {
          sub: "Bebé",
          url: {
            producto: "sneakers",
            categoria: "bebe",
          },
          img: "assets/images/bebe.jpg",
        },
        {
          sub: "Todos",
          url: {
            producto: "sneakers",
          },
          img: "assets/images/nino.jpg",
        },
        /*        {
                  'sub': 'Outlet',
                  'url': 'collection/6',
                  'img': 'assets/images/disc.jpg',
                  'id': '6'
                },*/
        {
          sub: "Artículos de limpieza",
          url: {
            producto: "crep",
          },
          img: "assets/images/creep.jpg",
        },
      ],
    },
    {
      name: "Ropa",
      image: "assets/images/ropa.jpg",
      url: "/productos/ropa",
      subcategorias: [
        {
          sub: "Chamarras y Hoodies",
          url: {
            producto: "chamarra",
          },
          img: "assets/images/chamarra.jpg",
        },
        {
          sub: "Pants",
          url: {
            producto: "pants",
          },
          img: "assets/images/pants.jpg",
        },
        {
          sub: "Shorts",
          url: {
            producto: "short",
          },
          img: "assets/images/short.jpg",
        },
        {
          sub: "Playeras",
          url: {
            producto: "playera",
          },
          img: "assets/images/playera.jpg",
        },
      ],
    },
    {
      name: "Distribuidores",
      subcategorias: [
        {
          sub: "Con inversión",
          url: "/distribuidores/con-inversion",
          sections: [],
        },
        {
          sub: "Independientes",
          url: "/distribuidores/independientes",
          sections: [],
        },
      ],
    },
  ];
  suscribeCarrito: Subscription;
  menuCategorias = false;
  categoriaEscogida: number;


  constructor(
    private fix: LandingFixService,
    private loginService: UserstoreService,
    private carritoService: CarritoService,
    public productsService: ProductosService,
    public dialog: MatDialog,
    private router: Router
  ) {
    const session = sessionStorage.getItem("carrito");
    if (session) {
      this.elementosCarrito = JSON.parse(window.atob(session)).length;
    }
    this.suscribeCarrito = this.carritoService.elementosCarrito.subscribe(
      (elementosCarrito) => (this.elementosCarrito = elementosCarrito.length)
    );
    this.tokenIsValid = loginService.validToken();
  }

  ngOnInit() {
    $.getScript("assets/js/menu.js");
    if (this.tokenIsValid) {
      this.getUser();
    }
  }

  ngOnDestroy(): void {
    this.suscribeCarrito.unsubscribe();
  }

  ngAfterViewInit() {
    const $src = fromEvent(window, "scroll").pipe(debounceTime(400));
    $src.subscribe(() => {
      if (this.showLogin) {
        this.showLogin = false;
      }
    });
  }

  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  openSearch() {
    this.show = true;
  }

  closeSearch() {
    this.show = false;
  }

  openDialog(): void {
    this.dialog.open(RegisterUserComponent, {
      width: "350px",
    });
  }

  openLogin(): void {
    this.dialog.open(LoginComponent, {
      width: "350px",
    });
  }

  logout(): void {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("infoCliente");
    sessionStorage.removeItem("metodoPago");
    window.location.reload();
  }

  getUser() {
    this.loginService.getUser().subscribe((data) => (this.user = data));
  }

  showMobileMenu() {
    this.showMenu = !this.showMenu;
  }

  showMobileMenuNav() {
    setTimeout(() => {
      this.showMenu = !this.showMenu;
    }, 300);
  }

  cerrarMenu() {
    this.menuCategorias = false;
  }

  mostrarMenu(e, id) {
    e.sections && e.sections.length > 0
      ? (this.menuCategorias = true)
      : (this.menuCategorias = false);
    this.idMarcaUrl = id;
  }

  navMarca(subId, id) {
    this.productsService.filtroIdMarca.next(id);
    this.router.navigate(["collection", this.idMarcaUrl]);
  }

  navCategoria(id) {
    // console.log(id);
    this.productsService.filtroIdMarca.next(null);
    this.router.navigate(["collection", id]);
  }


  seleccionaCategoria(numero) {
    this.categoriaEscogida = numero;
  }

  rastrearPedido() {
    this.router.navigate(["/rastrear-pedido"]);
  }
}
