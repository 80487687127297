import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material';
import {Notificaciones} from '../../../../notificaciones';
import {UserstoreService} from '../../../../core/services/nuevaLogica/clientes/userstore.service';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent {
  genero = [
    {
      value: 'M',
      descripcion: 'Masculino'
    },
    {
      value: 'F',
      descripcion: 'Femenino'
    }
  ];
  userForm = new FormGroup({
    nombre: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^([A-Za-z ÑñáéíóúÁÉÍÓÚ]+)$')])),
    apellidos: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^([A-Za-z ÑñáéíóúÁÉÍÓÚ]+)$')
    ])),
    telefono: new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[0-9]+$')
    ])),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    repeatPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    fechaNacimiento: new FormControl('', Validators.required),
    genero: new FormControl('', Validators.required)
  });

  constructor(protected userStoreService: UserstoreService,
              public dialogRef: MatDialogRef<RegisterUserComponent>,
              private notificaciones: Notificaciones) {
  }

  registerUser() {
    this.notificaciones.carga('Ingresando datos');
    this.userStoreService.post({
      user: window.btoa(JSON.stringify(this.userForm.value))
    }).subscribe(userData => {
        this.notificaciones.exito('Registro completado');
        sessionStorage.setItem('token', userData.token);
        window.location.reload();
      }, error => {
        if (error.status === 422) {
          this.notificaciones.error('Este correo ya se encuentra registrado');
          this.userForm.controls.email.setValue('');
          this.userForm.controls.password.setValue('');
          this.userForm.controls.repeatPassword.setValue('');
        } else {
          this.notificaciones.error('Ha ocurrido un error, vuelve a intentarlo más tarde');
        }
      }
    );
  }

  comprobarPass(): Promise<any> {
    // debugger;
    return new Promise((resolve) => {
      if (this.userForm && (this.userForm.controls.repeatPassword.value === this.userForm.controls.password.value)) {
        resolve(null);
      } else {
        resolve({noMatch: true});
      }
    });
  }
}

