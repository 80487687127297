import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-detalles-producto',
  templateUrl: './detalles-producto.component.html',
  styleUrls: ['./detalles-producto.component.scss']
})
export class DetallesProductoComponent implements OnInit {
  datosProducto = new FormGroup({
    talla: new FormControl('', Validators.required),
    cantidad: new FormControl(1, Validators.compose(
      [Validators.required, Validators.min(1), Validators.pattern('[0-9]+')]))
  });
  tallas: any;
  cantidades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  formularioDinamico2: FormGroup;
  mostrarDetalles = false;
  counter: number;

  constructor(public dialogRef: MatDialogRef<DetallesProductoComponent>,
              private _fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.tallas = JSON.parse(this.data.talla);

    if (this.data.cantidad) {
      this.datosProducto.controls.cantidad.setValue(this.data.cantidad);
    }

    // @ts-ignore
    this.formularioDinamico2 = this._fb.group({
      cantidadProductos: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]')])),
      detalleProducto: this._fb.array([this.agregarDetallesProductoGroup()])
    });
  }

  agregarDetallesProductoGroup() {
    return this._fb.group({
      productoNumero: new FormControl('', Validators.required),
      talla: new FormControl('', Validators.required),
    });
  }

  agregarDetallesProducto(cantidad) {
    this.mostrarDetalles = true;
    this.counter = cantidad;
    for (let i = 0; i < cantidad - 1; i++) {
      this.detallesProductoArray.push(this.agregarDetallesProductoGroup());
      this.detallesProductoArray.controls[i].controls['productoNumero'].setValue(i + 1);
      this.detallesProductoArray.controls[this.detallesProductoArray.length - 1].controls['productoNumero'].setValue(this.detallesProductoArray.length);
    }
  }

  cambio(cantidad) {
    this.counter = cantidad;
    this.detallesProductoArray.clear();
    this.agregarDetallesProducto(this.counter + 1);
  }

  get detallesProductoArray(): any {
    return this.formularioDinamico2.get('detalleProducto') as FormArray;
  }

  guardar() {
    console.log(this.formularioDinamico2.value, 'this.formularioDinamico2.value');
    for (let i = 0; i < this.detallesProductoArray.length; i++) {
      this.data.cantidad = this.detallesProductoArray.controls[i].controls['productoNumero'].value;
      this.data.tallaSeleccionada = this.detallesProductoArray.controls[i].controls['talla'].value;
      i === this.detallesProductoArray.length - 1
        ? this.addToCart(this.data, 1, this.data.tallaSeleccionada, this.data.cantidadDescuento, 1)
        : this.addToCart(this.data, 1, this.data.tallaSeleccionada, this.data.cantidadDescuento);
    }
  }

  addToCart(product, quantity, size, cantidadDescuento?, ultimo?) {
    if (quantity === 0) {
      return false;
    }

    if (product.idDescuentoCatalogo !== null && product.idTipoDescuento === 1) {
      product.precioVenta = (product.precioVenta - (product.precioVenta * product.cantidadDescuento / 100));
      // this.cartService.addToCartLanzamientos(product, parseInt(quantity, 0), size, cantidadDescuento, ultimo);
      this.dialogRef.close(this.data);
    }

    if (product.idDescuentoCatalogo !== null && product.idTipoDescuento === 2) {
      product.precioVenta = product.precioVenta - product.cantidadDescuento;
      // this.cartService.addToCartLanzamientos(product, parseInt(quantity, 0), size, cantidadDescuento, ultimo);
      this.dialogRef.close(this.data);
    }
    if (product.idDescuentoCatalogo === null) {
      // this.cartService.addToCartLanzamientos(product, parseInt(quantity, 0), size, cantidadDescuento, ultimo);
      this.dialogRef.close(this.data);
    }
  }

  dismiss(producto?) {
    this.dialogRef.close(producto);
  }
}
