import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ShopComponent} from './shop.component';
import {LoginMobileComponent} from '../layout/header/login-mobile/login-mobile.component';
import {LanzamientosComponent} from './lanzamientos/lanzamientos.component';

const routes: Routes = [
  {
    path: '',
    component: ShopComponent,
    children: [
      {
        path: 'login',
        component: LoginMobileComponent
      },
      {
        path: 'lanzamientos',
        component: LanzamientosComponent
      },
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'rifa',
        loadChildren: () => import('./rifa/rifa.module').then(m => m.RifaModule)
      },
      {
        path: 'distribuidores',
        loadChildren: () => import('./distribuidores/distribuidores.module').then(m => m.DistribuidoresModule)
      },
      {
        path: 'outlet',
        loadChildren: () => import('./outlet/outlet.module').then(m => m.OutletModule)
      },
      {
        path: 'nuestra-empresa',
        loadChildren: () => import('./nuestra-empresa/nuestra-empresa.module').then(m => m.NuestraEmpresaModule)
      },
      {
        path: 'atencion-clientes',
        loadChildren: () => import('./atencion-clientes/atencion-clientes.module').then(m => m.AtencionClientesModule)
      },
      {
        path: 'productos',
        loadChildren: () => import('./productos/productos.module').then(m => m.ProductosModule)
      },
      {
        path: 'agendar-cita',
        loadChildren: () => import('./agendar-cita/agendar-cita.module').then(m => m.AgendarCitaModule)
      },
      {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule {
}
