import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {User} from '../../../interfaces/clientes/User';

@Injectable({
  providedIn: 'root'
})
export class UserstoreService {
  url: string;
  token: any;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlClientes + 'usuarios/ecommerce';
    this.token = sessionStorage.getItem('token');
  }

  post(user): Observable<any> {
    return this.http.post(this.url, user);
  }

  getUser(): Observable<{nombre: string}> {
    return this.http.get<{nombre: string}>(this.url);
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(this.url, user);
  }

  getInfoUser(): Observable<User> {
    return this.http.get<User>(this.url + '/info');
  }

  getCodigo(): Observable<any> {
    return this.http.get<string>(this.url + '/codigo');
  }

  getDirecciones(): Observable<any> {
    return this.http.get<string>(this.url + '/direcciones');
  }

  login(credenciales: string): Observable<any> {
    return this.http.post(this.url + '/login', {credenciales});
  }

  validToken() {
    if (this.token) {
      const now = Date.now().valueOf() / 1000;
      const jwt = jwt_decode(this.token);

      if (now < jwt.exp) {
        return true;
      } else {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('infoCliente');
        sessionStorage.removeItem('metodoPago');
        return false;
      }
    } else {
      return false;
    }
  }
}
