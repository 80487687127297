import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Carrito} from '../../../interfaces/checkout/carrito';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  elementosCarrito = new Subject<Carrito[]>();

  constructor() {
    this.elementosCarrito.next(this.getCarrito());
  }

  updateCarrito(infoCarrito) {
    const carritoActual = sessionStorage.getItem('carrito') ? this.desencriptarCarrito() : [];

    if (carritoActual.length > 0) {
      for (let i = 0; i < carritoActual.length; i++) {
        if ((i === carritoActual.length - 1) && carritoActual[i].idTallaSeleccionada !== infoCarrito.idTallaSeleccionada) {
          carritoActual.push(infoCarrito);
          break;
        }
        if (carritoActual[i].idTallaSeleccionada === infoCarrito.idTallaSeleccionada) {
          carritoActual[i].cantidad += infoCarrito.cantidad;
        }
      }
    } else {
      carritoActual.push(infoCarrito);
    }

    this.guardarCarrito(carritoActual);
    this.elementosCarrito.next(carritoActual);
  }

  getCarrito(): Carrito[] {
    return sessionStorage.getItem('carrito') ? this.desencriptarCarrito() : null;
  }

  eliminarElementoCarrito(index: number): Carrito[] {
    const carrito = this.desencriptarCarrito();
    carrito.splice(index, 1);
    this.elementosCarrito.next(carrito);
    this.guardarCarrito(carrito);
    return carrito;
  }

  modificarCantidadProducto (agregarProducto: boolean, index: number) {
    const carrito = this.desencriptarCarrito();
    if (agregarProducto) {
      carrito[index].cantidad++;
    } else {
      carrito[index].cantidad--;
    }
    this.guardarCarrito(carrito);
    return carrito;
  }

  private desencriptarCarrito() {
    return JSON.parse(window.atob(sessionStorage.getItem('carrito')));
  }

  private guardarCarrito(carritoActual) {
    sessionStorage.setItem('carrito', window.btoa(JSON.stringify(carritoActual)));
  }
}
