import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BenefictsService {

    url: any;

    constructor(private http: HttpClient) {
        this.url = environment.apiUrl;
    }

    getInstituciones(): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'benefits/instituciones');
    }

    getBeneficiariosByIdInstitucion(id): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'benefits/beneficiarios/institucion/' + id);
    }

    getBeneficiarioByIdUsuario() {
        const jwt = jwt_decode(sessionStorage.getItem('token'));
        return this.http.get<any>(this.url + 'getBenefictsByIdUser/' + jwt.sub);
    }

    saveBenefict(benefict): Observable<any> {
        return this.http.post<any>(this.url + 'beneficts', benefict);
    }
}
