import {Component, Input} from '@angular/core';
import {Producto} from '../../../core/interfaces/productos/Producto';

@Component({
  selector: 'app-tarjeta-productos',
  templateUrl: './tarjeta-productos.component.html',
  styleUrls: ['./tarjeta-productos.component.scss']
})
export class TarjetaProductosComponent {
  @Input() producto: Producto;

  constructor() { }

}
